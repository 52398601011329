import { ServerConfig, PartnerId } from './config.interface';

const highlandSportConfig: ServerConfig = {
  id: 'highlandsport',
  code: 'havlickuv-brod-highland-sport',
  displayPartnerPages: true,
  cssTheme: 'highlandsport',
  imageToPreload: [],
  faviconList: [
    "<link rel='icon' type='image/x-icon' href='/assets/higland-favicon.ico' sizes='32x32'>"
  ]
};

export default highlandSportConfig;
